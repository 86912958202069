import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";
import Seo from "../components/seo";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import * as ROUTES from "../constants/routes";

const FeaturePostTemplate = ({
  title,
  content,
  contentComponent,
  image,
  imageAlt,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="blog-post">
      {helmet || ""}
      <PageHeader title={title} />
      <div className="feature-container">
        <div>
          <PreviewCompatibleImage
            imageInfo={{
              image,
              alt: imageAlt,
            }}
          />
        </div>

        <div className="content-wrapper">
          <PostContent content={content} />

          <div className="sign-up-now-container">
            <button
              className="sign-up-now feature-button"
              onClick={() => navigate(ROUTES.SIGN_UP)}
            >
              Sign Up Now!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturePostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

const FeaturePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FeaturePostTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
        imageAlt={post.frontmatter.image_alt}
        helmet={
          <Seo
            title={`${post.frontmatter.title} | Feature`}
            description={post.frontmatter.title}
            image={post.frontmatter.image.childImageSharp.fluid.src}
          />
        }
      />
    </Layout>
  );
};

FeaturePost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FeaturePost;

export const featurePostQuery = graphql`
  query FeaturePost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image_alt
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
